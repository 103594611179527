AFRAME.registerComponent('pv-output', {
    schema: {
        value: {type:'string', default: '7.5 kW'}
    },
    init: function () {
      // Create and animate digits with a delay
      this.createDigitParticles(this.el);
    },
    createDigitParticles: function (cableEntity) {
      const particleCount = 5000;
      const dissolveDuration = 6000; // Duration in milliseconds
      const delayBetweenParticles = 3000; // Delay between each particle in milliseconds
  
        for (let i = 0; i < particleCount; i++) {
          setTimeout(() => {
            // Randomize position around the cable
            const posX = -9;
            const posY = 3.0;
            const posZ = 2.5;
  
            // Create digit particle entity
            const digitEntity = document.createElement('a-text');
            digitEntity.setAttribute('value', this.data.value);
            digitEntity.setAttribute("position", { x: posX, y: posY, z: posZ });
            digitEntity.setAttribute('color', '#FFFF00' ); // Yellow color in hex
            digitEntity.setAttribute('scale', '1 1 1');
  
            // Append to the scene
            cableEntity.appendChild(digitEntity);
  
            // Animate the digit particle
            this.animateDigitParticle(digitEntity, dissolveDuration);
          }, i * delayBetweenParticles); // Apply delay between particles
        };
    },
    animateDigitParticle: function (digitEntity, duration) {
        // Animate the digit particle to dissolve after 'duration' milliseconds
        
        digitEntity.setAttribute('animation', {
            property: 'position',
            dur: duration,
            easing: 'linear',
            to: '-1.2 3.0 2.5' // Move 10 units to the right (adjust this value as needed)
        });
        // Remove the digit particle after animation completes
        setTimeout(() => {
            digitEntity.parentNode.removeChild(digitEntity);
        }, duration);
    }
  });